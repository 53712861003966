<ng-container *ngIf="isPaging() ? pagedCiphers : ciphers as filteredCiphers">
  <table
    class="table table-hover table-list table-ciphers"
    *ngIf="filteredCiphers.length"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollDisabled]="!isPaging()"
    (scrolled)="loadMore()"
  >
    <tbody>
      <tr *ngFor="let c of filteredCiphers">
        <td (click)="checkCipher(c)" class="table-list-checkbox">
          <input type="checkbox" [(ngModel)]="c.checked" appStopProp />
        </td>
        <td (click)="checkCipher(c)" class="table-list-icon">
          <app-vault-icon [cipher]="c"></app-vault-icon>
        </td>
        <td (click)="checkCipher(c)" class="reduced-lh wrap">
          <a
            appStopProp
            [routerLink]="[]"
            [queryParams]="{ cipherId: c.id }"
            queryParamsHandling="merge"
            title="{{ 'editItem' | i18n }}"
            >{{ c.name }}</a
          >
          <ng-container *ngIf="c.hasAttachments">
            <i
              class="bwi bwi-paperclip"
              appStopProp
              title="{{ 'attachments' | i18n }}"
              aria-hidden="true"
            ></i>
            <span class="sr-only">{{ "attachments" | i18n }}</span>
            <ng-container *ngIf="showFixOldAttachments(c)">
              <i
                class="bwi bwi-exclamation-triangle text-warning"
                appStopProp
                title="{{ 'attachmentsNeedFix' | i18n }}"
                aria-hidden="true"
              ></i>
              <span class="sr-only">{{ "attachmentsNeedFix" | i18n }}</span>
            </ng-container>
          </ng-container>
          <br />
          <small appStopProp>{{ c.subTitle }}</small>
        </td>
        <td *ngIf="organizations.length > 0 && !organization" class="tw-w-28">
          <app-org-badge
            organizationName="{{ c.organizationId | orgNameFromId: organizations }}"
            profileName="{{ profileName }}"
            (onOrganizationClicked)="onOrganizationClicked(c.organizationId)"
          >
          </app-org-badge>
        </td>
        <td class="table-list-options">
          <button
            [bitMenuTriggerFor]="cipherOptions"
            class="tw-border-none tw-bg-transparent tw-text-main"
            type="button"
            appA11yTitle="{{ 'options' | i18n }}"
          >
            <i class="bwi bwi-ellipsis-v bwi-lg" aria-hidden="true"></i>
          </button>
          <bit-menu #cipherOptions>
            <ng-container *ngIf="c.type === cipherType.Login && !c.isDeleted">
              <button bit-menu-item (click)="copy(c, c.login.username, 'username', 'Username')">
                <i class="bwi bwi-fw bwi-clone" aria-hidden="true"></i>
                {{ "copyUsername" | i18n }}
              </button>
              <button
                bit-menu-item
                (click)="copy(c, c.login.password, 'password', 'Password')"
                *ngIf="c.viewPassword"
              >
                <i class="bwi bwi-fw bwi-clone" aria-hidden="true"></i>
                {{ "copyPassword" | i18n }}
              </button>
              <button
                bit-menu-item
                (click)="copy(c, c.login.totp, 'verificationCodeTotp', 'TOTP')"
                *ngIf="displayTotpCopyButton(c)"
              >
                <i class="bwi bwi-fw bwi-clone" aria-hidden="true"></i>
                {{ "copyVerificationCode" | i18n }}
              </button>
              <button bit-menu-item *ngIf="c.login.canLaunch" (click)="launch(c.login.launchUri)">
                <i class="bwi bwi-fw bwi-share-square" aria-hidden="true"></i>
                {{ "launch" | i18n }}
              </button>
            </ng-container>
            <button bit-menu-item (click)="attachments(c)">
              <i class="bwi bwi-fw bwi-paperclip" aria-hidden="true"></i>
              {{ "attachments" | i18n }}
            </button>
            <button
              bit-menu-item
              *ngIf="((!organization && !c.organizationId) || organization) && !c.isDeleted"
              (click)="clone(c)"
            >
              <i class="bwi bwi-fw bwi-files" aria-hidden="true"></i>
              {{ "clone" | i18n }}
            </button>
            <button
              bit-menu-item
              *ngIf="!organization && !c.organizationId && !c.isDeleted"
              (click)="share(c)"
            >
              <i class="bwi bwi-fw bwi-arrow-circle-right" aria-hidden="true"></i>
              {{ "moveToOrganization" | i18n }}
            </button>
            <button bit-menu-item *ngIf="c.organizationId && !c.isDeleted" (click)="collections(c)">
              <i class="bwi bwi-fw bwi-collection" aria-hidden="true"></i>
              {{ "collections" | i18n }}
            </button>
            <button bit-menu-item *ngIf="c.organizationId && accessEvents" (click)="events(c)">
              <i class="bwi bwi-fw bwi-file-text" aria-hidden="true"></i>
              {{ "eventLogs" | i18n }}
            </button>
            <button bit-menu-item (click)="restore(c)" *ngIf="c.isDeleted">
              <i class="bwi bwi-fw bwi-undo" aria-hidden="true"></i>
              {{ "restore" | i18n }}
            </button>
            <button bit-menu-item (click)="delete(c)">
              <span class="tw-text-danger">
                <i class="bwi bwi-fw bwi-trash" aria-hidden="true"></i>
                {{ (c.isDeleted ? "permanentlyDelete" : "delete") | i18n }}
              </span>
            </button>
          </bit-menu>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="no-items" *ngIf="!filteredCiphers.length">
    <ng-container *ngIf="!loaded">
      <i
        class="bwi bwi-spinner bwi-spin text-muted"
        title="{{ 'loading' | i18n }}"
        aria-hidden="true"
      ></i>
      <span class="sr-only">{{ "loading" | i18n }}</span>
    </ng-container>
    <ng-container *ngIf="loaded">
      <p>{{ "noItemsInList" | i18n }}</p>
      <button (click)="addCipher()" class="btn btn-outline-primary" *ngIf="showAddNew">
        <i class="bwi bwi-plus bwi-fw"></i>{{ "addItem" | i18n }}
      </button>
    </ng-container>
  </div>
</ng-container>
