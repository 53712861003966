<app-callout type="tip" title="{{ 'prerequisite' | i18n }}">
  {{ "requireSsoPolicyReq" | i18n }}
</app-callout>

<div class="form-group">
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      id="enabled"
      [formControl]="enabled"
      name="Enabled"
    />
    <label class="form-check-label" for="enabled">{{ "enabled" | i18n }}</label>
  </div>
</div>

<div [formGroup]="data">
  <div class="form-group">
    <label for="hours">{{ "maximumVaultTimeoutLabel" | i18n }}</label>
    <div class="row">
      <div class="col-6">
        <input
          id="hours"
          class="form-control"
          type="number"
          min="0"
          name="hours"
          formControlName="hours"
        />
        <small>{{ "hours" | i18n }}</small>
      </div>
      <div class="col-6">
        <input
          id="minutes"
          class="form-control"
          type="number"
          min="0"
          max="59"
          name="minutes"
          formControlName="minutes"
        />
        <small>{{ "minutes" | i18n }}</small>
      </div>
    </div>
  </div>
</div>
