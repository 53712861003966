<ng-container *ngIf="!loaded">
  <i
    class="bwi bwi-spinner bwi-spin text-muted tw-m-2"
    title="{{ 'loading' | i18n }}"
    aria-hidden="true"
  ></i>
  <span class="sr-only">{{ "loading" | i18n }}</span>
</ng-container>
<div *ngIf="loaded" class="tw-max-w-[300px] tw-min-w-[200px] tw-flex tw-flex-col">
  <button
    *ngIf="allowEnrollmentChanges(organization) && !organization.resetPasswordEnrolled"
    class="dropdown-item"
    (click)="toggleResetPasswordEnrollment(organization)"
  >
    <i class="bwi bwi-fw bwi-key" aria-hidden="true"></i>
    {{ "enrollPasswordReset" | i18n }}
  </button>
  <button
    *ngIf="allowEnrollmentChanges(organization) && organization.resetPasswordEnrolled"
    class="dropdown-item"
    (click)="toggleResetPasswordEnrollment(organization)"
  >
    <i class="bwi bwi-fw bwi-undo" aria-hidden="true"></i>
    {{ "withdrawPasswordReset" | i18n }}
  </button>
  <ng-container *ngIf="organization.useSso && organization.identifier">
    <button
      *ngIf="organization.ssoBound; else linkSso"
      class="dropdown-item"
      (click)="unlinkSso(organization)"
    >
      <i class="bwi bwi-fw bwi-chain-broken" aria-hidden="true"></i>
      {{ "unlinkSso" | i18n }}
    </button>
    <ng-template #linkSso>
      <app-link-sso [organization]="organization"> </app-link-sso>
    </ng-template>
  </ng-container>
  <button class="dropdown-item text-danger" (click)="leave(organization)">
    <i class="bwi bwi-fw bwi-sign-out" aria-hidden="true"></i>
    {{ "leave" | i18n }}
  </button>
</div>
