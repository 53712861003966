<div class="container page-content">
  <app-callout type="warning" title="{{ 'sendDisabled' | i18n }}" *ngIf="disableSend">
    <span>{{ "sendDisabledWarning" | i18n }}</span>
  </app-callout>
  <div class="row">
    <div class="col-3 groupings">
      <div class="card vault-filters">
        <div class="card-header d-flex">
          {{ "filters" | i18n }}
        </div>
        <div class="card-body">
          <input
            type="search"
            placeholder="{{ searchPlaceholder || ('searchSends' | i18n) }}"
            id="search"
            class="form-control"
            [(ngModel)]="searchText"
            (input)="searchTextChanged()"
            autocomplete="off"
            appAutofocus
          />
          <div class="filter">
            <ul class="filter-options">
              <li class="filter-option" [ngClass]="{ active: selectedAll }">
                <span class="filter-buttons">
                  <button bit-button class="filter-button" appStopClick (click)="selectAll()">
                    <i class="bwi bwi-fw bwi-filter"></i>{{ "allSends" | i18n }}
                  </button>
                </span>
              </li>
            </ul>
          </div>
          <div class="filter">
            <div class="filter-heading">
              <h3>{{ "types" | i18n }}</h3>
            </div>
            <ul class="filter-options">
              <li class="filter-option" [ngClass]="{ active: selectedType === sendType.Text }">
                <span class="filter-buttons">
                  <button
                    bit-button
                    class="filter-button"
                    appStopClick
                    (click)="selectType(sendType.Text)"
                  >
                    <i class="bwi bwi-fw bwi-file-text"></i>{{ "sendTypeText" | i18n }}
                  </button>
                </span>
              </li>
              <li class="filter-option" [ngClass]="{ active: selectedType === sendType.File }">
                <span class="filter-buttons">
                  <button
                    bit-button
                    class="filter-button"
                    appStopClick
                    (click)="selectType(sendType.File)"
                  >
                    <i class="bwi bwi-fw bwi-file"></i>{{ "sendTypeFile" | i18n }}
                  </button>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-9">
      <div class="page-header d-flex">
        <h1>
          {{ "send" | i18n }}
          <small #actionSpinner [appApiAction]="actionPromise">
            <ng-container *ngIf="actionSpinner.loading">
              <i
                class="bwi bwi-spinner bwi-spin text-muted"
                title="{{ 'loading' | i18n }}"
                aria-hidden="true"
              ></i>
              <span class="sr-only">{{ "loading" | i18n }}</span>
            </ng-container>
          </small>
        </h1>
        <div class="ml-auto d-flex">
          <button
            type="button"
            class="btn btn-outline-primary btn-sm"
            (click)="addSend()"
            [disabled]="disableSend"
          >
            <i class="bwi bwi-plus bwi-fw" aria-hidden="true"></i>{{ "createSend" | i18n }}
          </button>
        </div>
      </div>
      <!--Listing Table-->
      <table class="table table-hover table-list" *ngIf="filteredSends && filteredSends.length">
        <tbody>
          <tr *ngFor="let s of filteredSends">
            <td class="table-list-icon">
              <div class="icon" aria-hidden="true">
                <i class="bwi bwi-fw bwi-lg bwi-file" *ngIf="s.type == sendType.File"></i>
                <i class="bwi bwi-fw bwi-lg bwi-file-text" *ngIf="s.type == sendType.Text"></i>
              </div>
            </td>
            <td class="reduced-lh wrap">
              <a href="#" appStopClick appStopProp (click)="editSend(s)">{{ s.name }}</a>
              <ng-container *ngIf="s.disabled">
                <i
                  class="bwi bwi-exclamation-triangle"
                  appStopProp
                  title="{{ 'disabled' | i18n }}"
                  aria-hidden="true"
                ></i>
                <span class="sr-only">{{ "disabled" | i18n }}</span>
              </ng-container>
              <ng-container *ngIf="s.password">
                <i
                  class="bwi bwi-key"
                  appStopProp
                  title="{{ 'password' | i18n }}"
                  aria-hidden="true"
                ></i>
                <span class="sr-only">{{ "password" | i18n }}</span>
              </ng-container>
              <ng-container *ngIf="s.maxAccessCountReached">
                <i
                  class="bwi bwi-ban"
                  appStopProp
                  title="{{ 'maxAccessCountReached' | i18n }}"
                  aria-hidden="true"
                ></i>
                <span class="sr-only">{{ "maxAccessCountReached" | i18n }}</span>
              </ng-container>
              <ng-container *ngIf="s.expired">
                <i
                  class="bwi bwi-clock"
                  appStopProp
                  title="{{ 'expired' | i18n }}"
                  aria-hidden="true"
                ></i>
                <span class="sr-only">{{ "expired" | i18n }}</span>
              </ng-container>
              <ng-container *ngIf="s.pendingDelete">
                <i
                  class="bwi bwi-trash"
                  appStopProp
                  title="{{ 'pendingDeletion' | i18n }}"
                  aria-hidden="true"
                ></i>
                <span class="sr-only">{{ "pendingDeletion" | i18n }}</span>
              </ng-container>
              <br />
              <small appStopProp>{{ s.deletionDate | date: "medium" }}</small>
            </td>
            <td class="table-list-options">
              <button
                [bitMenuTriggerFor]="sendOptions"
                class="tw-border-none tw-bg-transparent tw-text-main"
                type="button"
                appA11yTitle="{{ 'options' | i18n }}"
              >
                <i class="bwi bwi-ellipsis-v bwi-lg" aria-hidden="true"></i>
              </button>
              <bit-menu #sendOptions>
                <button bit-menu-item (click)="copy(s)">
                  <i class="bwi bwi-fw bwi-clone" aria-hidden="true"></i>
                  {{ "copySendLink" | i18n }}
                </button>
                <button
                  bit-menu-item
                  (click)="removePassword(s)"
                  *ngIf="s.password && !disableSend"
                >
                  <i class="bwi bwi-fw bwi-close" aria-hidden="true"></i>
                  {{ "removePassword" | i18n }}
                </button>
                <button bit-menu-item (click)="delete(s)">
                  <span class="tw-text-danger">
                    <i class="bwi bwi-fw bwi-trash" aria-hidden="true"></i>
                    {{ "delete" | i18n }}
                  </span>
                </button>
              </bit-menu>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="no-items" *ngIf="filteredSends && !filteredSends.length">
        <ng-container *ngIf="!loaded">
          <i
            class="bwi bwi-spinner bwi-spin text-muted"
            title="{{ 'loading' | i18n }}"
            aria-hidden="true"
          ></i>
          <span class="sr-only">{{ "loading" | i18n }}</span>
        </ng-container>
        <ng-container *ngIf="loaded">
          <p>{{ "noSendsInList" | i18n }}</p>
          <button (click)="addSend()" class="btn btn-outline-primary" [disabled]="disableSend">
            <i class="bwi bwi-plus bwi-fw"></i>{{ "createSend" | i18n }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<ng-template #sendAddEdit></ng-template>
