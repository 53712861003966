<div *ngIf="loaded && activeOrganization != null" class="tw-flex">
  <button
    class="tw-flex tw-items-center tw-bg-background-alt tw-border-none"
    type="button"
    id="pickerButton"
    [appA11yTitle]="'organizationPicker' | i18n"
    [bitMenuTriggerFor]="orgPickerMenu"
  >
    <app-avatar
      [data]="activeOrganization.name"
      size="45"
      [circle]="true"
      [dynamic]="true"
    ></app-avatar>
    <div class="tw-flex">
      <div class="org-name tw-ml-3">
        <span>{{ activeOrganization.name }}</span>
        <small class="tw-text-muted">{{ "organization" | i18n }}</small>
      </div>
      <div class="tw-ml-3">
        <i class="bwi bwi-angle-down tw-text-main" aria-hidden="true"></i>
      </div>
    </div>
  </button>
  <div>
    <div
      class="tw-ml-3 tw-border tw-border-solid tw-rounded tw-border-danger-500 tw-text-danger"
      *ngIf="!activeOrganization.enabled"
    >
      <div class="tw-py-2 tw-px-5">
        <i class="bwi bwi-exclamation-triangle" aria-hidden="true"></i>
        {{ "organizationIsDisabled" | i18n }}
      </div>
    </div>
    <div
      class="tw-ml-3 tw-border tw-border-solid tw-rounded tw-border-info-500 tw-text-info"
      *ngIf="activeOrganization.isProviderUser"
    >
      <div class="tw-py-2 tw-px-5">
        <i class="bwi bwi-exclamation-triangle" aria-hidden="true"></i>
        {{ "accessingUsingProvider" | i18n: activeOrganization.providerName }}
      </div>
    </div>
  </div>

  <bit-menu #orgPickerMenu>
    <ul aria-labelledby="pickerButton" class="tw-p-0 tw-m-0">
      <li *ngFor="let org of organizations" class="tw-list-none tw-flex tw-flex-col" role="none">
        <a bit-menu-item [routerLink]="['/organizations', org.id]">
          <i
            class="bwi bwi-check mr-2"
            [ngClass]="org.id === activeOrganization.id ? 'visible' : 'invisible'"
          >
            <span class="tw-sr-only">{{ "currentOrganization" | i18n }}</span>
          </i>
          {{ org.name }}
        </a>
      </li>
      <bit-menu-divider></bit-menu-divider>
      <li class="tw-list-none" role="none">
        <a bit-menu-item routerLink="/create-organization">
          <i class="bwi bwi-plus mr-2"></i>
          {{ "newOrganization" | i18n }}</a
        >
      </li>
    </ul>
  </bit-menu>
</div>
